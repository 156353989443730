import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7422e213"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "normal_card",
  id: "recursos"
}
const _hoisted_2 = { class: "card-recurso-with-circle" }
const _hoisted_3 = { class: "card-header-with-circle" }
const _hoisted_4 = {
  key: 0,
  class: "circle-green-xl"
}
const _hoisted_5 = {
  key: 1,
  class: "circle-red-xl"
}
const _hoisted_6 = { class: "recurso-card-body" }
const _hoisted_7 = { class: "image-container-recurso" }
const _hoisted_8 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_card = _resolveComponent("v-card")

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "card-recurso",
    width: "100%",
    onClick: _cache[0] || (_cache[0] = $event => ($options.selectRecurso($props.recurso)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("a", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createCommentVNode(" Cabeçalho do card "),
          _createElementVNode("div", _hoisted_3, [
            _createCommentVNode(" Bolinha indicativa (Status) "),
            ($data.apontamento && $data.apontamento.tipo == 'Produção')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4))
              : (_openBlock(), _createElementBlock("div", _hoisted_5)),
            _createVNode(_component_v_card_title, { class: "card-title-name-recurso" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.recurso.nome), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createCommentVNode(" Corpo do card "),
          _createElementVNode("div", _hoisted_6, [
            _createCommentVNode(" Imagem "),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("img", {
                class: "image",
                src: $props.recurso.imagem || '/assets/nxlite/images/img-maquina.jpg',
                alt: "Imagem do recurso"
              }, null, 8 /* PROPS */, _hoisted_8)
            ])
          ])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}