<template>
    <div class="text-center">
        <v-dialog v-model="appStore.Modal_Op_head" max-width="900px">
            <v-card class="pa-4">
                <v-icon @click="closeModalOpHead">
                    mdi-close
                </v-icon>
                <div class="text-center mb-4">
                    <h3 class="text-h5 font-weight-medium">
                        Detalhes da OP - {{ appStore.selected_ordem_de_producao?.name || "-" }}
                    </h3>
                    <hr>
                </div>

                <v-row dense>
                    <v-col cols="12" md="6">
                        <v-card outlined class="pa-3" color="grey-lighten-2">
                            <v-card-text>
                                <v-card-text class="font-weight-bold">Descrição/Item: {{
                                    appStore.selected_ordem_de_producao?.descricao || "-" }}</v-card-text>
                                <hr>
                                <v-card-text class="font-weight-bold">
                                    Pedido: {{
                                        appStore.selected_ordem_de_producao?.pedido || "-" }}

                                </v-card-text>
                                <hr>
                                <v-card-text class="font-weight-bold">
                                    Cliente:
                                    {{ appStore.selected_ordem_de_producao?.cliente || "-" }}
                                </v-card-text>
                                <hr>
                                <v-card-text class="font-weight-bold">
                                    Data de entrega:
                                    {{ appStore.selected_ordem_de_producao
                                        ? frappe.format(appStore.selected_ordem_de_producao.dt_entrega, {
                                            fieldtype:
                                                "Datetime"
                                        })
                                        : "-"
                                    }}
                                </v-card-text>
                                <hr>
                                <v-card-text class="font-weight-bold">
                                    Início:
                                    {{ appStore.selected_ordem_de_producao
                                        ? frappe.format(appStore.selected_ordem_de_producao.dt_inicio, {
                                            fieldtype: "Date"
                                        })
                                        : "-"
                                    }}
                                </v-card-text>
                                <hr>
                                <v-card-text class="font-weight-bold">Quantidade: {{
                                    appStore.selected_ordem_de_producao?.quantidade || "-" }}</v-card-text>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card outlined class="pa-3" color="grey-lighten-2">
                            <v-card-text>
                                <v-card-text class="font-weight-bold">Quantidade Realizada: {{
                                    appStore.selected_ordem_de_producao?.quantidade_realizada || "-" }}</v-card-text>
                                <hr>
                                <v-card-text class="font-weight-bold">Desenho: {{
                                    appStore.selected_ordem_de_producao?.desenho || "-" }}</v-card-text>
                                <hr>
                                <v-card-text class="font-weight-bold">Revisão: {{
                                    appStore.selected_ordem_de_producao?.revisao || "-" }}</v-card-text>
                                <hr>
                                <v-card-text class="font-weight-bold">Posição: {{
                                    appStore.selected_ordem_de_producao?.posicao || "-" }}</v-card-text>
                                <hr>
                                <v-card-text class="font-weight-bold">Estrutura: {{
                                    appStore.selected_ordem_de_producao?.estrutura || "-" }}</v-card-text>
                                <hr>
                                <v-card-text class="font-weight-bold">
                                    ㅤ
                                    ㅤ <!--aqui tem 2 caracteres inviviveis -->
                                </v-card-text>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <div>
                    <v-card outlined class="pa-3 mt-4" color="grey-lighten-2">
                        <v-card-text class="font-weight-bold"> <span>Observação: {{
                            appStore.selectedordem_de_producao?.observacao
                                || "-" }}</span> </v-card-text>
                    </v-card>
                </div>


                <div class="text-center mt-4">
                    <v-btn color="primary" @click="appStore.closeModalOpHead">OK</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>



<style scoped></style>

<script>
import { useAppStore } from "../../../stores/app";

export default {
    name: "ModalOpHead",
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        appStore() {
            return useAppStore();
        },
    },
    methods: {
        openModalOpPlus() {
            this.appStore.openModalOpHead()
        },
        closeModalOpHead() {
            this.appStore.closeModalOpHead();
            this.appStore.selectItem("ordem_de_producao", null);
            this.appStore.selectItem("apontamento", null);
        },
    },
};
</script>
