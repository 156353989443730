<template>
	<v-container fluid>
		<div name="conteiner-buttons" id="conteiner-buttons">
			<div :class="display == 'sm' ? 'pl-4' : ''" name="conteiner-buttons">
				<div class="row-button">
					<div class="row-status d-flex justify-center align-center mt-6">
						<!-- Apontamento -->
						<v-btn v-bind:class="{ selected: selectedTab === 'apontamentos_producao' }" rounded
							variant="text" @click="change_render('apontamentos_producao')" id="apontamento-btn"
							class="status background-verde button clicksombra mx-1 sm:mx-2">
							<div class="text-column-subheader"></div>
							<div class="columns-container">
								<div class="text-line">
									<div class="text-column centralizador bottom font-size"
										v-html="windowWidth <= 599 ? '<div style=\'font-size: 30px\' >🛠️</div>' : windowWidth <= 800 ? 'Apontamento<br> Aberto' : 'Apontamento Aberto'">
									</div>

								</div>
							</div>
						</v-btn>

						<!-- Parada -->
						<v-btn v-bind:class="{ selected: selectedTab === 'apontamentos_parada' }" rounded variant="text"
							@click="change_render('apontamentos_parada')" id="motivo-parada-btn"
							class="status background-laranja button clicksombra mx-1 sm:mx-2">
							<div class="text-column-subheader"></div>
							<div class="columns-container">
								<div class="text-line" id="selected_motivo_de_parada"></div>
								<div class="text-line">
									<div class="text-column centralizador bottom font-size">
										<div
											v-html="windowWidth <= 599 ? '<div style=\'font-size: 30px\' >⚠️</div>' : windowWidth <= 800 ? 'Motivo de <br> Parada' : 'Motivo de Parada'">
										</div>
									</div>
								</div>
							</div>
						</v-btn>

						<!-- Ordem de Produção -->
						<v-btn v-bind:class="{ selected: selectedTab === 'ordens_de_producao' }" rounded variant="text"
							@click="change_render('ordens_de_producao')" id="Opbtn"
							class="status background-vermelho button clicksombra mx-1 sm:mx-2">
							<div class="columns-container">
								<div class="image-container" v-if="appStore.selected_ordem_de_producao">
									<img class="circular-image ml-0" :src="getSeletedOPSrc()" alt="Imagem">
								</div>
								<div class="text-content"
									style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
									<div class="text-column centralizador bottom font-size">
										<div v-if="appStore.selected_ordem_de_producao">
											<span>{{ appStore.selected_ordem_de_producao.name }}</span><br />
											<span class="limpar-text mt-2">Limpar</span>
										</div>
										<div v-else>
											<span
												v-html="windowWidth <= 599 ? '<div style=\'font-size: 30px\' >📃</div>' : windowWidth <= 800 ? 'Ordem de <br> Produção' : 'Ordem de Produção'"></span>
										</div>
									</div>
								</div>
							</div>
						</v-btn>

						<!-- Recursos -->
						<v-btn v-bind:class="{ selected: selectedTab === 'recursos' }" rounded variant="text"
							id="recurso-btn" @click="change_render('recursos')"
							class="status background-cinza button clicksombra mx-1 sm:mx-2">
							<div class="columns-container">
								<div class="image-container-rec" v-if="appStore.selected_recurso">
									<img class="circular-image"
										:src="appStore.selected_recurso.imagem || '/assets/nxlite/images/img-maquina.jpg'"
										alt="Imagem">
								</div>
								<div class="text-line"
									style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center;">
									<div class="text-column centralizador bottom font-size">
										<div v-if="appStore.selected_recurso">
											<span>{{ appStore.selected_recurso.nome }}</span><br />
											<span class="limpar-text mt-2">Limpar</span>
										</div>
										<div v-else>
											<span
												v-html="windowWidth <= 599 ? '<div style=\'font-size: 30px\' >⚙️</div>' : windowWidth <= 800 ? 'Recurso' : 'Recurso'"></span>
										</div>
									</div>
								</div>
							</div>
						</v-btn>

						<!-- Operadores -->
						<v-btn v-bind:class="{ selected: selectedTab === 'operadores' }" rounded variant="text"
							@click="change_render('operadores')" id="operador-btn"
							class="status background-azul button clicksombra mx-1 sm:mx-2">
							<div class="columns-container">
								<div class="image-container" v-if="appStore.selected_operador">
									<img class="circular-image"
										:src="appStore.selected_operador ? (appStore.selected_operador.foto || 'https://cdn-icons-png.flaticon.com/512/7425/7425445.png') : 'https://cdn-icons-png.flaticon.com/512/7425/7425445.png'"
										alt="Imagem">
								</div>
								<div class="text-line font-size">
									<div v-if="appStore.selected_operador">
										<span>{{ appStore.selected_operador.nome }}</span><br />
										<span class="limpar-text mt-2">Limpar</span>
									</div>
									<div v-else>
										<span
											v-html="windowWidth <= 599 ? '<div style=\'font-size: 30px\' >👨‍💼</div>' : windowWidth <= 800 ? 'Operador' : 'Operador'"></span>
									</div>
								</div>
							</div>
						</v-btn>
					</div>

				</div>

			</div>
			<v-row class="row-status d-flex justify-sm-end pr-sm-10 justify-lg-center align-center mt-3 pt-0">
				<v-col cols="11" class="d-flex justify-end justify-cols-center">
					<!-- Campo de Filtro por Data -->
					<v-col class="d-flex justify-center" cols="5" sm="3" md="3" lg="3" xl="3" xxl="3">
						<div class="d-flex" style="width: 100% !important;"
							v-if="appStore.type_view !== 'operadores' && appStore.type_view !== 'recursos'">
							<label for="input_data" style="margin-bottom: 5px;" class="form-label icon-label">
								<v-icon style="margin-right: 5px; margin-top: 6px;"
									v-if="windowWidth <= 800">mdi-calendar</v-icon>
							</label>
							<input id="input_data" name="pesquisa_data" style="width: 100% !important; border: 3px solid #BEBEBE;"
								placeholder="dd/mm/aaaa" class="form-control input-field"
								@change="(e) => { set_filter('date', e.target.value) }" aria-label="Pesquisar"
								type="date" v-model="appStore.filter.date" />
						</div>
					</v-col>

					<!-- Campo de Pesquisa Geral -->
					<v-col cols="4" class="d-flex justify-center" :sm="3" :md="3" :lg="3" :xl="3" :xxl="3"
						:class="{ 'sm-margin-5': true }">
						<input id="pesquisa_geral" class="form-control input-field" style="border: 3px solid #BEBEBE;" placeholder="Pesquisar"
							aria-label="Pesquisar" @change="(e) => { set_filter('match', e.target.value) }"
							type="search" v-model="appStore.filter.match" />
					</v-col>

					<!-- Botão de Pesquisa -->
					<v-col cols="3" class="d-flex justify-center align-center" sm="3" md="3" lg="2" xl="3" xxl="3">
						<button id="pesquisa" class="btn btn-primary action-btn size-button" type="button"
							@click="render_filter()">Pesquisar</button>
					</v-col>

					<!-- Botão de Abrir MP -->
					<v-col v-if="windowWidth > 599" cols="2" pt-0
						class="d-flex justify-sm-center justify-md-center align-center px-1" sm="2" md="3" lg="3" xl="3"
						xxl="3">
						<v-btn v-if="appStore.type_view == 'apontamentos_parada'" style="height: 28px; font-size: 12px; border: 2px solid #BEBEBE"
							@click="openModalMotivoDeParada" @close="closeModalMotivoDeParada"
							class="action-btn btn-secondary size-buton-mp" v-html="TextMP"></v-btn>
					</v-col>
				</v-col>

				<!-- Botão de Tela Cheia (apenas para dispositivos maiores que 599px) -->
				<v-col v-if="windowWidth > 599" cols="1" class="d-flex justify-sm-center pt-0">
					<v-col cols="12" class="d-flex justify-center px-0" style="margin-bottom: 6px;">
						<v-btn class="rounded action-btn" height="26" icon @click="toggleFullScreen" color="secondary">
							<v-icon size="23">{{ isFullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
						</v-btn>
					</v-col>
				</v-col>

				<!-- Coluna para telas menores que 599px -->
				<v-col v-if="windowWidth < 599" cols="12" class="d-flex justify-sm-end pl-10 pt-0 pb-0">
					<!-- Botão de Abrir MP -->
					<v-col cols="6" class="d-flex align-center justify-center pt-0" >
						<v-btn v-if="appStore.type_view == 'apontamentos_parada'" style="height: 28px; font-size: 12px; border: 2px solid #BEBEBE"
							@click="openModalMotivoDeParada" @close="closeModalMotivoDeParada"
							class="action-btn btn-secondary size-buton-mp" v-html="TextMP"></v-btn>
					</v-col>
				</v-col>

				<!-- Modal -->
				<ModalMotivoDeParada :visible="appStore.Modal_Motivo_De_Parada" />
			</v-row>
		</div>
		<ModalMotivoDeParada :visible="appStore.Modal_Motivo_De_Parada" />

	</v-container>
</template>

<script>
import { useAppStore } from "../stores/app";
import ModalMotivoDeParada from "./components/modals/modal_motivo_de_parada.vue";
import { useDisplay } from 'vuetify'

export default {
	name: "appNavbar",
	components: {
		ModalMotivoDeParada,
	},
	data() {

		return {
			// src_image = 
			isFullScreen: false,
			selectedTab: 'apontamentos_producao',
			windowWidth: window.innerWidth,
			display: useDisplay().name
		};
	},
	computed: {
		appStore() {
			return useAppStore();
		},
		TextMP() {
			return this.windowWidth <= 800 ? ' Abrir MP' : 'Apontamentos Abertos';
		},
	},
	methods: {
		getSeletedOPSrc() {
			const selected_op = this.appStore.selected_ordem_de_producao;
			let srcSelectedOrdemDeProducao = null;
			if (selected_op) {
				if (selected_op.croqui_image) {
					srcSelectedOrdemDeProducao = selected_op.croqui_image
				} else if (selected_op.item) {
					if (selected_op.item.imagem_produto) {
						srcSelectedOrdemDeProducao = selected_op.item.imagem_produto
					} else {
						srcSelectedOrdemDeProducao = 'https://cdn-icons-png.flaticon.com/512/4358/4358521.png'
					}
				} else {
					srcSelectedOrdemDeProducao = 'https://cdn-icons-png.flaticon.com/512/4358/4358521.png'

				}
			}
			return srcSelectedOrdemDeProducao;
		},
		extractValues(data) {
			let result = [];
			function recursiveExtract(input) {
				if (Array.isArray(input)) {
					input.forEach((element) => recursiveExtract(element));
				} else if (input !== null && typeof input === "object") {
					Object.values(input).forEach((value) => recursiveExtract(value));
				} else {
					result.push(input);
				}
			}
			recursiveExtract(data);
			return result;
		},
		render_filter() {
			console.log('antes de renderizar (render):', this.appStore.render)
			this.appStore.render_view()
		},
		set_filter(type, value) {
			this.appStore.filter[type] = value;
		},
		change_render(tab) {
			this.appStore.type_view = tab;
			console.log(tab)
			this.selectedTab = tab;
			this.appStore.selectItem(this.appStore.translator.tab_to_selected[tab], null)
			if (this.appStore.filter.match === null && this.appStore.filter.date === null) {
				this.appStore.render = this.appStore[this.appStore.type_view];
			} else {
				this.render_filter();
			}
			this.appStore.render_filter_by_selecteds()
		},

		openModalMotivoDeParada() {
			this.appStore.openModalMotivoDeParada();
		},
		closeModalMotivoDeParada() {
			this.appStore.closeModalMotivoDeParada();
		},
		handleKeyDown(event) {
			if (event.key === "F11") {
				event.preventDefault();
				this.toggleFullScreen();
			}
		},
		toggleFullScreen() {
			if (!document.fullscreenElement) {
				document.documentElement.requestFullscreen()
					.then(() => {
						isFullScreen.value = true;
					})
					.catch((err) => {
						console.error("Erro ao tentar entrar em modo de tela cheia:", err);
					});
			} else {
				document.exitFullscreen()
					.then(() => {
						isFullScreen.value = false;
					})
					.catch((err) => {
						console.error("Erro ao tentar sair do modo de tela cheia:", err);
					});
			}
		},
		updateWindowWidth() {
			this.windowWidth = window.innerWidth;
		},
	},
	mounted() {
		if (!this.selectedTab) {
			this.selectedTab = 'apontamentos_producao';
		}
		window.addEventListener('resize', this.updateWindowWidth);
	}
}
</script>

<style>
#conteiner-buttons {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	background-color: white;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

}

#Opbtn {
	background-color: #D32F2F;
	height: 80px;
	width: 250px;
	display: flex;
	align-items: center;
	padding: 10px;
	color: #fff;
}

#recurso-btn {
	background-color: #757575;
	/* Cor cinza */
	height: 80px;
	width: 230px;
	display: flex;
	align-items: center;
	padding: 10px;
	color: #fff;
}

#operador-btn {
	background-color: #5DADE2;
	/* Cor cinza */
	height: 80px;
	width: 230px;
	display: flex;
	align-items: center;
	padding: 10px;
	color: #fff;
}

#motivo-parada-btn {
	background-color: #e3bb1a;
	/* Cor cinza */
	height: 80px;
	width: 230px;
	display: flex;
	align-items: center;
	padding: 10px;
	color: #fff;
}

#apontamento-btn {
	background-color: #28B463;
	/* Cor cinza */
	height: 80px;
	width: 230px;
	display: flex;
	align-items: center;
	padding: 10px;
	color: #fff;
}

.text-line {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.columns-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.image-container {
	display: flex;
	align-items: left;
	justify-content: left;
	margin-right: 10px;
	/* Ajuste para mover a imagem mais à esquerda */
}

.image-container-rec {
	display: flex;
	align-items: left;
	justify-content: left;
	margin-right: 20px;
	/* Ajuste para mover a imagem mais à esquerda */
}

.circular-image {
	width: 50px;
	/* Ajusta o tamanho da imagem */
	height: 50px;
	border-radius: 50%;
	/* Torna a imagem redonda */
	object-fit: cover;
	margin-right: 0px;
}

.text-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.limpar-text {
	font-size: 8px;
}

/* Estilo para botões gerais */
.button {
	transition: all 0.3s ease;
	/* Suaviza as transições */
	border: 2px solid transparent;
	/* Borda padrão transparente */
	padding: 10px 20px;
	/* Ajuste no espaçamento do botão */
	border-radius: 5px;
	/* Bordas arredondadas */
	outline: none;
	/* Remove o contorno padrão */
	cursor: pointer;
	/* Adiciona o cursor de ponteiro */
	position: relative;
	/* Necessário para posicionar a sombra */
}

/* Efeito de borda 3D e sombra */
.button:not(.selected) {
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5), 0 10px 15px rgba(0, 0, 0, 0.3);
	/* Sombra mais destacada e intensa */
	border: 3px solid rgba(0, 0, 0, 0.1);
	/* Borda suave, criando a sensação de profundidade */
}

/* Estilo para o botão selecionado */
.button.selected {
	border: 3px solid #FFD700;
	/* Borda forte e destacada */
	background-color: rgba(255, 255, 255, 0.15);
	/* Fundo semi-transparente */
	animation: pulseHighlight 2.5s infinite;
	/* Animação de pulsação */
	box-shadow: none;
	/* Remove a sombra para dar o efeito de "botão pressionado" */
}

/* Adaptação para botões com cores diferentes */
.button.background-verde.selected {
	--button-color: #28B463;
	/* Verde */
	border-color: #28B463;
}

.button.background-laranja.selected {
	--button-color: #e3bb1a;
	/* Laranja */
	border-color: #e3bb1a;
}

.button.background-vermelho.selected {
	--button-color: #D74848;
	/* Vermelho */
	border-color: #D74848;
}

.button.background-amarelo.selected {
	--button-color: #E9A662;
	/* Amarelo */
	border-color: #E9A662;
}

.button.background-roxo.selected {
	--button-color: #800080;
	/* Roxo */
	border-color: #800080;
}

.button.background-cinza.selected {
	--button-color: #808080;
	/* Cinza */
	border-color: #808080;
}

.button.background-azul.selected {
	--button-color: #1A90C9;
	/* Azul */
	border-color: #1A90C9;
}

/* Animação de pulsação com base na cor do botão */
@keyframes pulseHighlight {

	0%,
	100% {
		box-shadow: 0 0 10px 4px var(--button-color);
	}

	50% {
		box-shadow: 0 0 15px 6px var(--button-color);
	}
}

/* Efeito de "botão pressionado" */
.button:active {
	transform: translateY(4px);
	/* Move o botão para baixo, dando o efeito de pressão */
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
	/* Sombra mais suave e intensa quando pressionado */
	border: 3px solid #000;
	/* Coloca a borda preta ao pressionar */
}

/* Efeito de borda preta quando o botão está pressionado */
.button.selected:active {
	border-color: #000;
	/* Altera para borda preta ao ser pressionado */
}

.size-button {
	height: 28px;
	font-size: 12px;
	width: 100% !important;
}

.text-center.pa-4[visible="false"] {
	display: none !important;
}


@media (min-width: 600px) and (max-width: 1023px) {

	.text-center.pa-4[visible="false"] {
		display: none !important;
	}


	#conteiner-buttons {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1000;
		background-color: white;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

	}

	.text-center.pa-4 {
		display: none !important;
	}


	body {
		padding-top: 60px;
		/* Adapte conforme a altura do seu componente fixo */
	}

	/* .row-button {
	display: flex;
	justify-content: center;
	margin-bottom: 1px;
} */

	.row-status {
		display: flex;
		flex-wrap: wrap;
		width: 102%;
		margin: 20px -15px 0;
		justify-content: center;
	}

	.row-status button {
		margin-right: 5px;
		/* Ajuste o valor conforme necessário */
	}

	#Opbtn {
		background-color: #D32F2F;
		height: 80px;
		width: 18%;
		display: flex;
		align-items: center;
		padding: 10px;
		color: #fff;
	}

	#recurso-btn {
		background-color: #757575;
		/* Cor cinza */
		height: 80px;
		width: 18%;
		display: flex;
		align-items: center;
		padding: 10px;
		color: #fff;
	}

	#operador-btn {
		background-color: #5DADE2;
		/* Cor cinza */
		height: 80px;
		width: 18%;
		display: flex;
		align-items: center;
		padding: 10px;
		color: #fff;
	}

	#motivo-parada-btn {
		background-color: #e3bb1a;
		/* Cor cinza */
		height: 80px;
		width: 18%;
		display: flex;
		align-items: center;
		padding: 10px;
		color: #fff;
	}

	#apontamento-btn {
		background-color: #28B463;
		/* Cor cinza */
		height: 80px;
		width: 18%;
		display: flex;
		align-items: center;
		padding: 10px;
		color: #fff;
	}

	.text-line {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.columns-container {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.image-container {
		display: flex;
		align-items: left;
		justify-content: left;
		margin-right: 10px;
		/* Ajuste para mover a imagem mais à esquerda */
	}

	.image-container-rec {
		display: flex;
		align-items: left;
		justify-content: left;
		margin-right: 20px;
		/* Ajuste para mover a imagem mais à esquerda */
	}

	.circular-image {
		width: 50px;
		/* Ajusta o tamanho da imagem */
		height: 50px;
		border-radius: 50%;
		/* Torna a imagem redonda */
		object-fit: cover;
		margin-right: 0px;
	}

	.text-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.limpar-text {
		font-size: 8px;
	}

	.font-size {
		font-size: 11px
	}

	/* Estilo para botões gerais */
	.button {
		transition: all 0.3s ease;
		/* Suaviza as transições */
		border: 2px solid transparent;
		/* Borda padrão transparente */
		padding: 10px 20px;
		/* Ajuste no espaçamento do botão */
		border-radius: 5px;
		/* Bordas arredondadas */
		outline: none;
		/* Remove o contorno padrão */
		cursor: pointer;
		/* Adiciona o cursor de ponteiro */
		position: relative;
		/* Necessário para posicionar a sombra */
	}

	/* Efeito de borda 3D e sombra */
	.button:not(.selected) {
		box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5), 0 10px 15px rgba(0, 0, 0, 0.3);
		/* Sombra mais destacada e intensa */
		border: 3px solid rgba(0, 0, 0, 0.1);
		/* Borda suave, criando a sensação de profundidade */
	}

	/* Estilo para o botão selecionado */
	.button.selected {
		border: 3px solid #FFD700;
		/* Borda forte e destacada */
		background-color: rgba(255, 255, 255, 0.15);
		/* Fundo semi-transparente */
		animation: pulseHighlight 2.5s infinite;
		/* Animação de pulsação */
		box-shadow: none;
		/* Remove a sombra para dar o efeito de "botão pressionado" */
	}

	/* Adaptação para botões com cores diferentes */
	.button.background-verde.selected {
		--button-color: #28B463;
		/* Verde */
		border-color: #28B463;
	}

	.button.background-laranja.selected {
		--button-color: #e3bb1a;
		/* Laranja */
		border-color: #e3bb1a;
	}

	.button.background-vermelho.selected {
		--button-color: #D74848;
		/* Vermelho */
		border-color: #D74848;
	}

	.button.background-amarelo.selected {
		--button-color: #E9A662;
		/* Amarelo */
		border-color: #E9A662;
	}

	.button.background-roxo.selected {
		--button-color: #800080;
		/* Roxo */
		border-color: #800080;
	}

	.button.background-cinza.selected {
		--button-color: #808080;
		/* Cinza */
		border-color: #808080;
	}

	.button.background-azul.selected {
		--button-color: #1A90C9;
		/* Azul */
		border-color: #1A90C9;
	}

	.size-button {
		height: 28px;
		font-size: 12px;
		width: 100% !important;
	}

	/* Animação de pulsação com base na cor do botão */
	@keyframes pulseHighlight {

		0%,
		100% {
			box-shadow: 0 0 10px 4px var(--button-color);
		}

		50% {
			box-shadow: 0 0 15px 6px var(--button-color);
		}
	}

	/* Efeito de "botão pressionado" */
	.button:active {
		transform: translateY(4px);
		/* Move o botão para baixo, dando o efeito de pressão */
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
		/* Sombra mais suave e intensa quando pressionado */
		border: 3px solid #000;
		/* Coloca a borda preta ao pressionar */
	}

	/* Efeito de borda preta quando o botão está pressionado */
	.button.selected:active {
		border-color: #000;
		/* Altera para borda preta ao ser pressionado */
	}

	/* #pesquisa_geral {
	width: 80%;
	margin-left: 25%;
	margin-bottom: 9px;
} */

	#input_data {
		width: 125px;
		border: 1px solid #f0f0f0;
	}

}

@media (min-width: 1024px) and (max-width: 500px) {

.text-center.pa-4[visible="false"] {
	display: none !important;
}


#conteiner-buttons {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	background-color: white;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

}

.text-center.pa-4 {
	display: none !important;
}


body {
	padding-top: 60px;
	/* Adapte conforme a altura do seu componente fixo */
}

/* .row-button {
display: flex;
justify-content: center;
margin-bottom: 1px;
} */

.row-status {
	display: flex;
	flex-wrap: wrap;
	width: 102%;
	margin: 20px -15px 0;
	justify-content: center;
}

.row-status button {
	margin-right: 5px;
	/* Ajuste o valor conforme necessário */
}

#Opbtn {
	background-color: #D32F2F;
	height: 80px;
	width: 18%;
	display: flex;
	align-items: center;
	padding: 10px;
	color: #fff;
}

#recurso-btn {
	background-color: #757575;
	/* Cor cinza */
	height: 80px;
	width: 18%;
	display: flex;
	align-items: center;
	padding: 10px;
	color: #fff;
}

#operador-btn {
	background-color: #5DADE2;
	/* Cor cinza */
	height: 80px;
	width: 18%;
	display: flex;
	align-items: center;
	padding: 10px;
	color: #fff;
}

#motivo-parada-btn {
	background-color: #e3bb1a;
	/* Cor cinza */
	height: 80px;
	width: 18%;
	display: flex;
	align-items: center;
	padding: 10px;
	color: #fff;
}

#apontamento-btn {
	background-color: #28B463;
	/* Cor cinza */
	height: 80px;
	width: 18%;
	display: flex;
	align-items: center;
	padding: 10px;
	color: #fff;
}

.text-line {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.columns-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.image-container {
	display: flex;
	align-items: left;
	justify-content: left;
	margin-right: 10px;
	/* Ajuste para mover a imagem mais à esquerda */
}

.image-container-rec {
	display: flex;
	align-items: left;
	justify-content: left;
	margin-right: 20px;
	/* Ajuste para mover a imagem mais à esquerda */
}

.circular-image {
	width: 50px;
	/* Ajusta o tamanho da imagem */
	height: 50px;
	border-radius: 50%;
	/* Torna a imagem redonda */
	object-fit: cover;
	margin-right: 0px;
}

.text-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.limpar-text {
	font-size: 8px;
}

.font-size {
	font-size: 11px
}

/* Estilo para botões gerais */
.button {
	transition: all 0.3s ease;
	/* Suaviza as transições */
	border: 2px solid transparent;
	/* Borda padrão transparente */
	padding: 10px 20px;
	/* Ajuste no espaçamento do botão */
	border-radius: 5px;
	/* Bordas arredondadas */
	outline: none;
	/* Remove o contorno padrão */
	cursor: pointer;
	/* Adiciona o cursor de ponteiro */
	position: relative;
	/* Necessário para posicionar a sombra */
}

/* Efeito de borda 3D e sombra */
.button:not(.selected) {
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5), 0 10px 15px rgba(0, 0, 0, 0.3);
	/* Sombra mais destacada e intensa */
	border: 3px solid rgba(0, 0, 0, 0.1);
	/* Borda suave, criando a sensação de profundidade */
}

/* Estilo para o botão selecionado */
.button.selected {
	border: 3px solid #FFD700;
	/* Borda forte e destacada */
	background-color: rgba(255, 255, 255, 0.15);
	/* Fundo semi-transparente */
	animation: pulseHighlight 2.5s infinite;
	/* Animação de pulsação */
	box-shadow: none;
	/* Remove a sombra para dar o efeito de "botão pressionado" */
}

/* Adaptação para botões com cores diferentes */
.button.background-verde.selected {
	--button-color: #28B463;
	/* Verde */
	border-color: #28B463;
}

.button.background-laranja.selected {
	--button-color: #e3bb1a;
	/* Laranja */
	border-color: #e3bb1a;
}

.button.background-vermelho.selected {
	--button-color: #D74848;
	/* Vermelho */
	border-color: #D74848;
}

.button.background-amarelo.selected {
	--button-color: #E9A662;
	/* Amarelo */
	border-color: #E9A662;
}

.button.background-roxo.selected {
	--button-color: #800080;
	/* Roxo */
	border-color: #800080;
}

.button.background-cinza.selected {
	--button-color: #808080;
	/* Cinza */
	border-color: #808080;
}

.button.background-azul.selected {
	--button-color: #1A90C9;
	/* Azul */
	border-color: #1A90C9;
}

.size-button {
	height: 28px;
	font-size: 12px;
	width: 100% !important;
}

/* Animação de pulsação com base na cor do botão */
@keyframes pulseHighlight {

	0%,
	100% {
		box-shadow: 0 0 10px 4px var(--button-color);
	}

	50% {
		box-shadow: 0 0 15px 6px var(--button-color);
	}
}

/* Efeito de "botão pressionado" */
.button:active {
	transform: translateY(4px);
	/* Move o botão para baixo, dando o efeito de pressão */
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
	/* Sombra mais suave e intensa quando pressionado */
	border: 3px solid #000;
	/* Coloca a borda preta ao pressionar */
}

/* Efeito de borda preta quando o botão está pressionado */
.button.selected:active {
	border-color: #000;
	/* Altera para borda preta ao ser pressionado */
}

/* #pesquisa_geral {
width: 80%;
margin-left: 25%;
margin-bottom: 9px;
} */

#input_data {
	width: 125px;
	border: 1px solid #f0f0f0;
}

}

@media (max-width: 599px) {

	.text-center.pa-4[visible="false"] {
		display: none !important;
	}

	#conteiner-buttons {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1000;
		background-color: white;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

	}

	.v-row {
		/* Aqui você pode redefinir ou desabilitar as propriedades que não deseja aplicar */
		display: block;
		/* Exemplo de redefinir para block, caso queira que não use flex */
		flex: none;
		/* Remover qualquer comportamento de flex */
		flex-wrap: unset;
		/* Remover wrap */
		margin: 0;
		/* Remover margens */
	}

	.row-status {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin: 10px -5px 0;
		justify-content: center;
	}

	.row-status button {
		margin-right: 2px;
		margin-bottom: 5px;
	}

	#Opbtn,
	#recurso-btn,
	#operador-btn,
	#motivo-parada-btn,
	#apontamento-btn {
		height: 67px;
		width: 14%;
		margin: 5px;
		align-items: center;
		justify-content: center;
		padding: 5px;
		color: #fff;
		font-size: 12px;
		text-align: center;
	}

	.size-button {
		height: 28px;
		font-size: 9px;
		width: 100% !important;
	}

	.size-buton-mp {
		height: 28px;
		font-size: 12px;
	}


	.text-line,
	.text-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 10px;
	}

	.image-container,
	.image-container-rec {
		margin-right: 5px;
	}

	.circular-image {
		width: 40px;
		height: 40px;
	}

	.limpar-text {
		font-size: 7px;
	}

	.font-size {
		font-size: 10px;
	}

	#input_data {
		width: 100px;
	}
}
</style>
