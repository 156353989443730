<template>

    <v-card class="card-recurso" width="100%" @click="selectOperador(operador)">
      <a class="normal_card" id="operadores">
        <div class="card-recurso-with-circle">
          <!-- Cabeçalho do card -->
          <div class="card-header-with-circle">
            <!-- Bolinha indicativa (Status) -->
            <div v-if="apontamento && apontamento.tipo === 'Produção'" class="circle-green-xl"></div>
            <div v-else class="circle-red-xl"></div>
            <v-card-title class="card-title-name-recurso">
              {{ operador.nome }}
            </v-card-title>
          </div>

          <!-- Corpo do card -->
          <div class="operador-card-body">
            <div class="image-container-recurso">
              <!-- Imagem do operador -->
              <img class="image" :src="operador.foto || 'https://cdn-icons-png.flaticon.com/512/7425/7425445.png'"
                alt="Logo NXLite Operador">
            </div>
          </div>
        </div>
      </a>
    </v-card>

</template>

<style scoped>
.card-recurso {
  border-radius: 15px;
  max-width: 450px;
  margin-bottom: 20px;
  border: 3px solid #BEBEBE;
  width: 250px; /* Tamanho uniforme para os dois cards */
  height: 243px;
}

.normal_card {
  text-decoration: none;
  color: inherit;
}

.card-recurso-with-circle {
  display: flex;
  flex-direction: column;
  height: auto;
}

.card-header-with-circle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 15px 15px 0 0;
}

.circle-green-xl {
  width: 15px;
  height: 15px;
  background-color: #28a745;
  border-radius: 50%;
}

.circle-red-xl {
  width: 15px;
  height: 15px;
  background-color: #dc3545;
  border-radius: 50%;
}

.card-title-name-recurso {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  flex-grow: 1;
}

.operador-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  border-radius: 0 0 15px 15px;
}

.image-container-recurso {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
</style>





<script>
import { useAppStore } from "../../stores/app";

export default {
  name: "appCardOperador",
  props: {
    operador: Object
  },
  data() {
    return {
      apontamento: this.operador.apontamentos.length != 0 ? this.operador.apontamentos[0] : null,
    };
  },
  computed: {
    appStore() {
      return useAppStore();
    },
    // Computed property para retornar o caminho da imagem do operador, se selecionado
    getImageSrc() {
      // Verifica se o operador tem uma imagem personalizada
      const operadorImagem = this.operador.image;
      return operadorImagem ? operadorImagem : '/assets/nxlite/images/img-operador.png'; // Imagem padrão
    }
  },

  methods: {
    selectOperador(operador) {
      console.log(operador)
      this.appStore.selectItem("operador", operador);
      this.appStore.create_apontamento()
      // this.appStore.selectedImageOperador(operador.foto);
    }

  },
};
</script>
