<template>
        <v-card 
        variant="outlined" 
        width="100%" 
        :height="display == 'xs' || display == 'sm' ? '94%' : '94%'"  
        class="card_class" 
        style="overflow-x: hidden;">
            <div class="card-circle mb-1">
                <div class="card-header-with-circle ">
                    <div class="header-content">
                        <p class="card-title mr-3">
                            {{ apontamento.name }} - {{ frappe.format(apontamento.ordem_de_producao.dt_entrega,
                                { "fieldtype": "Date" }) }}
                        </p>
                    </div>
                </div>
 
                <div class="card-body" @click="openModalParadaBody">
                    <div class="image-container mt-4 ">
                        <img src="https://img.freepik.com/vetores-premium/icone-de-sinal-de-parada-notificacoes-que-nao-fazem-nada_68708-468.jpg?w=360"
                            alt="Icone de Parada" class="round-image ">
                    </div>
                    <div class="info-container mt-5">
                        <div class="info-item mt-1">
                            <strong>Recurso:</strong>
                            <span>{{ apontamento.recurso.nome || ' -' }}</span>
                        </div>
                        <div class="info-item">
                            <strong>Operador:</strong>
                            <span>{{ apontamento.operador.nome || ' -' }}</span>
                        </div>
                        <div class="info-item">
                            <strong>Motivo Parada:</strong>
                            <span>{{ apontamento?.desc_motivo_parada || ' -' }}</span>
                        </div>
                        <div class="info-item">
                            <strong>OP:</strong>
                            <span>{{ apontamento.ordem_de_producao.name || ' -' }}</span>
                        </div>
                        <div class="info-item">
                            <strong>Tempo:</strong>
                            <span class="time-value">{{ duracao || ' -'}}</span>
                        </div>
                    </div>
                    <ModalMotivoDeParadaBody />
                </div>
            </div>
        </v-card>

</template>

<style scoped>
.div-principal {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px;
    height: 406px;
    width: 278px;
}

.card_class {
    margin-top: -20px;
    /* Move o card para cima */
    border-radius: 15px;
    margin-bottom: 20px;
    max-width: 600px;
    flex: 1 0 21%;
    margin: 10px;
    height: 100%;
    min-height: 220px;
    transition: none !important;
    border: 3px solid #BEBEBE;
    /* Remove qualquer animação */
}

.card_class:active,
.card_class:focus {
    box-shadow: none !important;
    /* Remove qualquer efeito visual ao clicar */
    transform: none !important;
    /* Remove transformações ao clicar */
}

.card_class:focus {
    outline: none !important;
    /* Remove o outline do foco */
}

.card_class:hover {
    background-color: transparent !important;
    /* Remover qualquer efeito de hover */
}

.normal_card {
    text-decoration: none;
    color: inherit;
}

.card-with-circle {
    display: flex;
    flex-direction: column;
    height: auto;
}

.card-header-with-circle {
    width: 100%;
    /* Ocupa toda a largura do card */
    align-items: center;
    /* Alinha os itens verticalmente */
    justify-content: space-between;
    /* Espaço entre os elementos */
    background-color: green;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}


.circle-text {
    color: white;
    font-size: 12px;
}

.header-content {
    display: flex;
    /* Ativa o flexbox */
    justify-content: center;
    /* Centraliza horizontalmente */
    align-items: center;
    /* Centraliza verticalmente */
    width: 100%;
    /* Garante que o contêiner ocupe toda a largura disponível */
    background-color: #f5f5f5;
    flex: 1;
    text-align: center;
    height: 49px;
}

.card-title {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin: 0;
    /* Remove margens extras */
    width: 100%;
    /* Garante que o título use a largura total */
    word-wrap: break-word;
    /* Quebra o texto, se necessário */
}

.btn-plus {
    color: 6c757d;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 25px;
    cursor: pointer;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
    background-color: white;
    margin-top: 5px;
    margin: 0;
    padding: 0;
    border: none;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#card {
    display: flex;
    justify-content: center;
}

.round-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.card_class:hover {
    cursor: pointer;
}

.info-container {
    width: 90%;
    text-align: left;
    margin-top: 20px;
}

.info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 9px;
    background-color: #f0f0f0;
    border-radius: 8px;
    border: 1px solid #ddd;
    
}

@media (max-width: 600px) {
.card_class{
    margin-left: 15px;
    left: -15px
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 15px
}

.round-image {
    margin-top: 15px;
    width: 60px; /* Tamanho maior para melhor visualização */
    height: 60px;
    object-fit: cover; /* Garante que a imagem se ajuste sem distorcer */
    border-radius: 50%;
   
  }

  .info-container {
    width: 95%;
    text-align: left;
    margin-top: 15px;
}

.info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 9px;
    background-color: #f0f0f0;
    border-radius: 8px;
    border: 1px solid #ddd;
    
}
}
</style>


<script>
import { useAppStore } from "../../stores/app";
import ModalMotivoDeParadaBody from "../components/modals/modal_motivo_de_parada_body.vue";
import {useDisplay} from 'vuetify'

export default {
    name: "appCardApontamentoParada",
    components: {
        ModalMotivoDeParadaBody
    },
    props: {
        apontamento: Object
    },
    data() {
        const duracao = this.calcularDuracaoDtInicio(this.apontamento.dt_inicio)
        return {
            display: useDisplay().name,  
            interval: setInterval(this.adicionarUmSegundo, 1000),
            duracao,
            apontamento: this.apontamento,
        };
    },
    computed: {
        appStore() {
            return useAppStore();
        },
    },
    beforeUnmount() {
        clearInterval(this.interval)
    },
    methods: {
        adicionarUmSegundo() {
            let [horas, minutos, segundos] = this.duracao.split(':').map(Number);
            segundos += 1;
            if (segundos === 60) {
                segundos = 0;
                minutos += 1;
            }
            if (minutos === 60) {
                minutos = 0;
                horas += 1;
            }
            const horasFormatadas = horas.toString().padStart(2, '0');
            const minutosFormatados = minutos.toString().padStart(2, '0');
            const segundosFormatados = segundos.toString().padStart(2, '0');
            this.duracao = `${horasFormatadas}:${minutosFormatados}:${segundosFormatados}`;
        },
        calcularDuracaoDtInicio(dataStr) {
            const dataInicial = moment(dataStr, 'YYYY-MM-DD HH:mm:ss');
            const agora = moment();
            const duracao = moment.duration(agora.diff(dataInicial));
            const horas = Math.floor(duracao.asHours()).toString().padStart(2, '0');
            const minutos = duracao.minutes().toString().padStart(2, '0');
            const segundos = duracao.seconds().toString().padStart(2, '0');
            return `${horas}:${minutos}:${segundos}`;
        },
        openModalParadaBody() {
            this.appStore.openModalParadaBody();

            this.appStore.selectItem('apontamento', this.apontamento)
        },
    },
};
</script>
