<template>
    <v-card variant="outlined" width="100%" class="card_class" v-ripple="false"
        @click="selectOrdemDeProducao(ordem_de_producao)" :style="{
            boxShadow: ordem_de_producao.criticidade === 'Urgente'
                ? '0px 4px 10px 2px #dc3545'
                : 'none'
        }" style="background-color: #fff;" height="95%">

        <a class="normal_card" id="ordem_de_producao_card">
            <div class="div-sus">
                <div class="card-with-circle">
                    <div class="card-header-with-circle" @click="appStore.openModalOpHead">
                        <div class="circle"
                            :style="{ backgroundColor: apontamento ? (apontamento.tipo === 'Produção' ? 'green' : 'yellow') : 'red' }">
                            <span class="circle-text"></span>
                        </div>
                        <div class="header-content">
                            <p class="card-title">
                                {{ ordem_de_producao.name }} -
                                {{ frappe.format(ordem_de_producao.dt_entrega, { "fieldtype": "Date" }) }}
                            </p>
                        </div>
                        <div class="header-btn-plus">
                            <input class="btn-plus" type="button" value="+" />
                        </div>
                    </div>
                    <ModalOpHead />
                    <div class="card-body">
                        <div class="image-container mb-1 mt-2">
                            <img :src="ordem_de_producao.croqui_image || (ordem_de_producao.item && (ordem_de_producao.item.imagem_produto) ? ordem_de_producao.item.imagem_produto : 'https://cdn-icons-png.flaticon.com/512/4358/4358521.png') || 'https://cdn-icons-png.flaticon.com/512/4358/4358521.png'"
                                alt="Imagem Redonda" class="round-image mb-1 mt-2" />
                        </div>
                        <div class="info-container">
                            <div class="info-item">
                                <strong>Cliente:</strong>
                                <span>{{ ordem_de_producao ?
                                    ordem_de_producao.cliente : ' -' }}</span>
                            </div>

                            <div class="info-item">
                                <strong>Item:</strong>

                                <span> {{
                                    (ordem_de_producao.item
                                        ? ordem_de_producao.item.descricao : ordem_de_producao.descricao_item ?
                                            ordem_de_producao.descricao_item : ' -')}}</span>
                            </div>
                            <div class="info-item">
                                <strong>Quantidade: </strong>
                                <span> {{ ordem_de_producao ? ordem_de_producao.quantidade : ' -' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </v-card>

</template>

<style scoped>
.div-principal {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px;
    width: 278px;
}

.card_class {
    border-radius: 15px;
    margin-bottom: 20px;
    max-width: 600px;
    flex: 1 0 21%;
    margin: 10px;
    height: 100%;
    min-height: 200px;
    transition: none !important;
    border: 3px solid #BEBEBE;
    /* Remove qualquer animação */
}

.card_class:active,
.card_class:focus {
    box-shadow: none !important;
    /* Remove qualquer efeito visual ao clicar */
    transform: none !important;
    /* Remove transformações ao clicar */
}

.card_class:focus {
    outline: none !important;
    /* Remove o outline do foco */
}

.card_class:hover {
    background-color: transparent !important;
    /* Remover qualquer efeito de hover */
}

.normal_card {
    text-decoration: none;
    color: inherit;
}

.card-with-circle {
    display: flex;
    flex-direction: column;
    height: auto;
}

.card-header-with-circle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 15px 15px 0 0;
    background-color: #f5f5f5;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}


.circle-text {
    color: white;
    font-size: 12px;
}

.header-content {
    flex: 1;
    text-align: center;
}

.card-title {
    margin: 0;
    font-weight: bold;
}

.btn-plus {
    color: 6c757d;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 25px;
    cursor: pointer;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
    background-color: white;
    width: 100%;
    background-color: white;
    margin-top: 5px;
    margin: 0;
    padding: 0;
    border: none;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#card {
    display: flex;
    justify-content: center;
}

.round-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.info-container {
    width: 90%;
    text-align: left;
    margin-top: 10px;
}

.info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    border: 1px solid #ddd;
}


@media (max-width: 600px) {
.card_class{
    margin-right: 15px;
    left: -10px;
}
.info-item {
    font-size: 10px
}
}

@media (min-width: 601px) and (max-width: 1024px) {
.info-item {
    font-size: 12px
}
}
</style>
<script>
import { useAppStore } from "../../stores/app";
import ModalOpHead from "../components/modals/modal_op_head.vue";

export default {
    components: {
        ModalOpHead
    },
    name: "appCardOrdemDeProducao",
    props: {
        ordem_de_producao: Object
    },
    data() {
        console.log("Ordem de produção: ", this.ordem_de_producao);

        // Calcular a duração inicial com base na data de entrega
        const duracao = this.calcularDuracaoDtInicio(this.ordem_de_producao.dt_entrega);

        return {
            selectedImageOrdem: null,  // Armazena a imagem selecionada
            apontamento: this.ordem_de_producao.apontamentos.length !== 0 ? this.ordem_de_producao.apontamentos[0] : null,
            duracao,  // Duração inicial da data de entrega
            ordem_de_producao: this.ordem_de_producao,
            interval: null,  // Intervalo para contagem regressiva
            contandoRegressivamente: true,  // Indicador de contagem regressiva
        };
    },
    computed: {
        appStore() {
            return useAppStore();
        },
    },
    mounted() {
        this.iniciarContagemRegressiva();  // Inicia a contagem ao montar o componente
        // Recupera a duracao do localStorage quando a página for carregada
        const duracaoSalva = localStorage.getItem('duracao');
        if (duracaoSalva) {
            this.duracao = duracaoSalva;
        }
    },
    beforeUnmount() {
        if (this.interval) {
            clearInterval(this.interval);  // Limpa o intervalo ao desmontar o componente
        }
    },
    methods: {
        iniciarContagemRegressiva() {
            this.interval = setInterval(this.decrementarTempo, 1000);  // Inicia a contagem regressiva
        },

        decrementarTempo() {
            const dataEntrega = moment(this.ordem_de_producao.dt_entrega, 'YYYY-MM-DD HH:mm:ss');
            const agora = moment();
            let duracao = moment.duration(dataEntrega.diff(agora));

            if (this.contandoRegressivamente) {
                // Se ainda estiver contando regressivamente
                if (duracao.asSeconds() > 0) {
                    // Calcula horas, minutos e segundos para a contagem regressiva
                    const horas = Math.floor(duracao.asHours()).toString().padStart(2, '0');
                    const minutos = duracao.minutes().toString().padStart(2, '0');
                    const segundos = duracao.seconds().toString().padStart(2, '0');

                    this.duracao = `${horas}:${minutos}:${segundos}`;
                    // Salva o tempo no localStorage a cada atualização
                    localStorage.setItem('duracao', this.duracao);
                } else {
                    // Se o tempo terminar, define como 00:00:00 e começa a incrementar
                    this.duracao = '00:00:00';
                    this.contandoRegressivamente = false;  // Muda para o modo de incremento
                }
            } else {
                // Agora incrementando
                let [horas, minutos, segundos] = this.duracao.split(':').map(Number);

                // Se o tempo de entrega já passou, as horas devem ser negativas
                if (duracao.asSeconds() < 0) {
                    horas = Math.floor(duracao.asHours());
                    minutos = duracao.minutes();
                    segundos = duracao.seconds();
                }

                // Se o tempo de atraso (negativo) já foi atingido, começamos a incrementar.
                let novoSegundos = segundos + 1;
                let novoMinutos = minutos;
                let novoHoras = horas;

                if (novoSegundos === 60) {
                    novoSegundos = 0;
                    novoMinutos += 1;
                }

                if (novoMinutos === 60) {
                    novoMinutos = 0;
                    novoHoras += 1;
                }

                // Ajuste para exibir horas negativas após 00:00:00
                if (novoHoras < 0) {
                    // Quando as horas estiverem negativas, formatamos para garantir que o sinal seja mantido
                    novoHoras = -Math.abs(novoHoras);
                }

                const horasFormatadas = novoHoras.toString().padStart(2, '0');
                const minutosFormatados = novoMinutos.toString().padStart(2, '0');
                const segundosFormatados = novoSegundos.toString().padStart(2, '0');

                // Atualiza a duração com o tempo incrementado ou em atraso
                this.duracao = `${horasFormatadas}:${minutosFormatados}:${segundosFormatados}`;
                // Salva o tempo incrementado no localStorage
                localStorage.setItem('duracao', this.duracao);
            }
        },

        calcularDuracaoDtInicio(dataStr) {
            const dataInicial = moment(dataStr, 'YYYY-MM-DD HH:mm:ss');
            const agora = moment();
            const duracao = moment.duration(dataInicial.diff(agora));
            const horas = Math.floor(duracao.asHours()).toString().padStart(2, '0');
            const minutos = duracao.minutes().toString().padStart(2, '0');
            const segundos = duracao.seconds().toString().padStart(2, '0');
            return `${horas}:${minutos}:${segundos}`;
        },

        selectOrdemDeProducao(ordem_de_producao) {
            this.appStore.selectItem("ordem_de_producao", ordem_de_producao);
            console.log(ordem_de_producao);
            this.appStore.create_apontamento();
        },

        openModalOpPlus() {
            this.appStore.openModalOpHead();
        }
    },
};
</script>
