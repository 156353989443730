<template>

    <v-card class="card-recurso" width="100%" @click="selectRecurso(recurso)">
      <a class=" normal_card" id="recursos">
        <div class="card-recurso-with-circle">
          <!-- Cabeçalho do card -->
          <div class="card-header-with-circle">
            <!-- Bolinha indicativa (Status) -->
            <div v-if="apontamento && apontamento.tipo == 'Produção'" class="circle-green-xl"></div>
            <div v-else class="circle-red-xl"></div>
            <v-card-title class="card-title-name-recurso">
              {{ recurso.nome }}
            </v-card-title>
          </div>

          <!-- Corpo do card -->
          <div class="recurso-card-body">
            <!-- Imagem -->
            <div class="image-container-recurso">
              <img class="image" :src="recurso.imagem || '/assets/nxlite/images/img-maquina.jpg'"
                alt="Imagem do recurso">
            </div>
          </div>
        </div>
      </a>
    </v-card>

</template>

<style scoped>

/* .div-principal {
  display: flex;
  justify-content: flex-start; 
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  padding-left: 10px; 
  width: 268px;
  height: 243px
  
} */

.card-recurso {
  border-radius: 15px;
  max-width: 450px;
  margin-bottom: 20px;
  border: 3px solid #BEBEBE;
  width: 250px;
  height: 243px 
}

.normal_card {
  text-decoration: none;
  color: inherit;
}

.card-recurso-with-circle {
  display: flex;
  flex-direction: column;
  height: auto;
}

.card-header-with-circle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid #e0e0e0;
  background-color: white;
  border-radius: 15px 15px 0 0;
}

.circle-green-xl {
  width: 15px;
  height: 15px;
  background-color: #28a745;
  border-radius: 50%;
}

.circle-red-xl {
  width: 15px;
  height: 15px;
  background-color: #dc3545;
  border-radius: 50%;
}

.card-title-name-recurso {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  flex-grow: 1;
}

.recurso-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  border-radius: 0 0 15px 15px;
}

.image-container-recurso {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
</style>

<script>
import { useAppStore } from "../../stores/app";

export default {
  name: "appCardRecurso",
  props: {
    recurso: Object
  },
  data() {
    return {
      selectedImage: null,  // Armazena a imagem selecionada
      apontamento: this.recurso.apontamentos.length !== 0 ? this.recurso.apontamentos[0] : null,
      selectedText: '',
    };
  },
  computed: {
    appStore() {
      return useAppStore();
    },
  },
  methods: {
    selectRecurso(recurso) {
      console.log("Recurso: ",recurso)
      this.appStore.selectItem("recurso", recurso);
      this.appStore.create_apontamento()
      this.selectedImage = recurso.image;
    },
  },

}
</script>